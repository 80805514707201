*
  margin: 0
  padding: 0
  box-sizing: border-box

body
  padding-top: 40px
  background: #181716
  font-family: Arial, sans-serif



.container
  width: 1350px
  color: white
  margin: 0 auto
  @media (max-width: 1350px)
    width: 95%
    margin: 0 auto

  @media (max-width: 1200px)

  @media (max-width: 900px)

  @media (max-width: 760px)

  @media (max-width: 480px)
    width: 90%
.html
  font-size: 35px
  color: #444242

h1
  font-size: 50px
  font-weight: bold
  color: #f15b63



.standardLittle
  color: #444242
  font-size: 15px

  @media (max-width: 1350px)

  @media (max-width: 1200px)

  @media (max-width: 900px)

  @media (max-width: 760px)

  @media (max-width: 480px)
    font-size: 11px
  a
    color: #444242
    text-decoration: none


.marginAdap
  @media (max-width: 1350px)

  @media (max-width: 1200px)

  @media (max-width: 900px)

  @media (max-width: 760px)

