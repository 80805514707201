body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.htmlOur {
    font-size: 35px;
    color: #444242;
}

.h1 {
    font-size: 50px;
    font-weight: bold;
    color: #f15b63;
}

.standard {
    color: #444242;
    font-size: 23px;
}

@media (max-width: 480px) {
    .standard{
        font-size: 19px;
    }
}

.services{
    color: #00DEDE;
    font-size: 23px;
    margin-left: 30px;
    display: inline-block;
}

.standardLittle {
    color: #444242;
    font-size: 15px;
    margin-left: 100px;
}


.standardLittle a{
    color: #444242;
    text-decoration: none;
}

@media (max-width: 480px){
    .standardLittle{
        font-size: 11px;
    }
}



@media (max-width: 480px){
    .marginAdap{
        position: relative;
        left: -6px;
    }
}
